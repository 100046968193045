<template>
    <div>
      <div class="banner">
          <img src="https://resources.holowits.com/announcement/announcement-banner.png" alt=""/>
      </div>
      <div class="txt" v-html="content"></div>
    </div>
  </template>
  
  <script>
  import { getTextById } from '@/api/announcementApi'
  export default {
  name: 'AnnouncementDetails',
  data() {
      return {
        content: ''
      }
  },
  created() {
    const id = this.$route.params.id
    this.getTextById(id)
  },
  methods: {
    async getTextById(id) {
        const {data: {data, isSuccess}} = await getTextById({id})
        if (isSuccess) {
            this.content = data.mainText
        }
    }
  }
  }
  </script>
  
  <style lang="less" scoped>
  .banner {
      // width: 100%;
      img {
          width: 100%;
      }
  }
  ::v-deep .txt {
    width: 1200px;
    margin: auto;
    padding-top: 15px;
    p {
      margin: 16px 0;
    }
    td {
      padding: 0 10px;
    }
  }
  </style>